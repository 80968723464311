<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="languages"
    item-value="name"
    :item-text="item => `${item.name} - ${item.description}`"
    :readonly="isEditing"
    return-object
  >
    <template v-slot:selection="data"> {{ data.item.name }} - {{ data.item.description }} </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "LanguageField",
  props: {
    value: [Object, String],
    language: Object,
    isEditing: Boolean,
    program: Object
  },
  data: () => ({
    languages: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      ApiService.get("/api/types/languages")
        .then(({ data }) => {
          this.languages = data;
        })
        .then(() => {
          this.emitValue(this.value);
        });
    },
    emitValue(v) {
      if (typeof v == "object") if (!this.value) this.$emit("input", v);
      if (typeof v == "string") {
        if (this.languages.length > 0) {
          let lang_obj = this.languages.find(x => x.name == v);
          this.$emit("input", lang_obj);
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        this.emitValue(v);
      }
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
