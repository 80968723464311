<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Promotion Type") }} - {{ form.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('PROMOTION_TYPE_UPDATE') && !isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onCopyConfirm" v-bind="attrs" v-on="on">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Copy") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('PROMOTION_TYPE_UPDATE') && isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('PROMOTION_TYPE_UPDATE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>
      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>{{ $i18n.translate("Details") }}</v-tab>
          <v-tab>{{ $i18n.translate("Terms & Conditions") }}</v-tab>
          <v-tab>{{ $i18n.translate("Promotion Validations") }}</v-tab>
          <v-tab>{{ $i18n.translate("Custom Fields") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <KeyField
                    name="promotionTypeKey"
                    label="Key"
                    id="promotionTypeKey"
                    v-model="form.promotionTypeKey"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'promotionTypeKey')"
                    @input="$error.clearValidationError(errors, 'promotionTypeKey')"
                    class="required"
                  ></KeyField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    name="name"
                    label="Name"
                    id="name"
                    v-model="form.name"
                    :rules="rules.name"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'name')"
                    @input="$error.clearValidationError(errors, 'name')"
                    class="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <PromotionInputTypeField
                    v-model="form.inputType"
                    :label="$i18n.translate('Input Type')"
                    :disabled="!isEditing"
                    :rules="rules.inputType"
                    class="required"
                  ></PromotionInputTypeField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    name="description"
                    label="Description"
                    id="description"
                    v-model="form.description"
                    :rules="rules.description"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'description')"
                    @input="$error.clearValidationError(errors, 'description')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <CountriesField
                    v-model="form.availableCountries"
                    name="availableCountries"
                    label="Available Countries"
                    id="availableCountries"
                    :disabled="!isEditing"
                    :rules="rules.availableCountries"
                    :error-messages="$error.getValidationError(errors, 'availableCountries')"
                    @input="$error.clearValidationError(errors, 'availableCountries')"
                    :program="selectedProgram"
                  ></CountriesField>
                </v-col>
                <v-col cols="6">
                  <AwardVehiclesField
                    v-model="selectedAvailableAwardVehicles"
                    name="availableAwardVehicles"
                    label="Available Award Vehicles"
                    id="availableAwardVehicles"
                    :disabled="!isEditing"
                    :rules="rules.availableAwardVehicles"
                    :error-messages="$error.getValidationError(errors, 'availableAwardVehicles')"
                    @input="$error.clearValidationError(errors, 'availableAwardVehicles')"
                  ></AwardVehiclesField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <DateTimePickerField
                    v-model="form.effectiveDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Effective Date')"
                    defaultTime="00:00"
                  ></DateTimePickerField>
                </v-col>
                <v-col cols="6">
                  <DateTimePickerField
                    v-model="form.expirationDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Expiration Date')"
                    defaultTime="23:59"
                  ></DateTimePickerField>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Upload Limit"
                    type="number"
                    min="1"
                    max="99"
                    v-model="form.uploadLimit"
                    :rules="rules.uploadLimit"
                    :disabled="!isEditing"
                    class="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    id="auditPercent"
                    v-model="form.auditPercent"
                    type="number"
                    label="Audit Percent"
                    :rules="rules.auditPercent"
                    :disabled="!isEditing"
                  ></v-text-field> </v-col
                ><v-col cols="3">
                  <v-switch
                    dense
                    label="Claim on behalf?"
                    v-model="form.allowClaimingOnBehalf"
                    :disabled="!isEditing"
                  ></v-switch
                ></v-col>
                <v-col cols="3">
                  <v-switch dense label="Claimable?" v-model="form.claimable" :disabled="!isEditing"></v-switch
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-switch
                    dense
                    label="Publicly Available?"
                    v-model="form.publiclyAvailable"
                    :disabled="!isEditing"
                  ></v-switch
                ></v-col>
                <v-col cols="4">
                  <v-switch
                    dense
                    label="Allow Product Price Entry"
                    v-model="form.allowProductPriceEntry"
                    :disabled="!isEditing"
                  ></v-switch
                ></v-col>
                <v-col cols="4">
                  <v-switch
                    dense
                    label="Allow Claims with No Payouts"
                    v-model="form.allowClaimsWithNoPayouts"
                    :disabled="!isEditing"
                  ></v-switch
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-switch
                    dense
                    label="Allow Within Email Communications"
                    v-model="form.allowWithinEmailCommunications"
                    :disabled="!isEditing"
                  ></v-switch>
                </v-col>
                <v-col cols="4">
                  <v-switch
                    dense
                    label="Allow Product Registration"
                    v-model="form.allowProductRegistration"
                    :disabled="!isEditing"
                  ></v-switch>
                </v-col>
                <v-col cols="4">
                  <v-switch
                    dense
                    label=" Based on Rule Engine?"
                    v-model="form.rulesEngineBased"
                    :disabled="!isEditing"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <TermsAndConditionsTable v-model="form.termsAndConditions" :disabled="!isEditing" />
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <PromotionValidationTypesField
                    v-model="selectedPromotionValidationTypes"
                    :disabled="!isEditing"
                    :label="'Promotion Validations'"
                  ></PromotionValidationTypesField>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-text-field
                      v-if="showUploadMinimumField"
                      label="Upload Minimum"
                      type="number"
                      min="1"
                      max="99"
                      v-model="form.uploadMinimum"
                      :disabled="!isEditing"
                      :rules="rules.uploadMinimum"
                      class="required"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-if="showPromotionProductMinimumField"
                      label="Promotion Product Minimum"
                      type="number"
                      min="1"
                      max="99"
                      v-model="form.promotionProductMinimum"
                      :disabled="!isEditing"
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <h2 v-if="selectedPromotionValidationTypes && selectedPromotionValidationTypes.length > 0">
                    The promotion to be claimable should:
                  </h2>
                  <ul v-if="selectedPromotionValidationTypes">
                    <li v-for="validationType in selectedPromotionValidationTypes" :key="validationType.name">
                      {{ validationType.description }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <CustomField
                    v-model="form.customFieldValue1"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 1
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[0]"
                  />

                  <CustomField
                    v-model="form.customFieldValue2"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 2
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[1]"
                  />

                  <CustomField
                    v-model="form.customFieldValue3"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 3
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[2]"
                  />

                  <CustomField
                    v-model="form.customFieldValue4"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 4
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[3]"
                  />

                  <CustomField
                    v-model="form.customFieldValue5"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 5
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[4]"
                  />

                  <CustomField
                    v-model="form.customFieldValue6"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 6
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[5]"
                  />

                  <CustomField
                    v-model="form.customFieldValue7"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 7
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[6]"
                  />

                  <CustomField
                    v-model="form.customFieldValue8"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 8
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[7]"
                  />

                  <CustomField
                    v-model="form.customFieldValue9"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields && selectedProgram.promotionTypeCustomFields.length >= 9
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[8]"
                  />

                  <CustomField
                    v-model="form.customFieldValue10"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields &&
                        selectedProgram.promotionTypeCustomFields.length >= 10
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[9]"
                  />

                  <CustomField
                    v-model="form.customFieldValue11"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields &&
                        selectedProgram.promotionTypeCustomFields.length >= 11
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[10]"
                  />

                  <CustomField
                    v-model="form.customFieldValue12"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields &&
                        selectedProgram.promotionTypeCustomFields.length >= 12
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[11]"
                  />

                  <CustomField
                    v-model="form.customFieldValue13"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields &&
                        selectedProgram.promotionTypeCustomFields.length >= 13
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[12]"
                  />

                  <CustomField
                    v-model="form.customFieldValue14"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields &&
                        selectedProgram.promotionTypeCustomFields.length >= 14
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[13]"
                  />

                  <CustomField
                    v-model="form.customFieldValue15"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.promotionTypeCustomFields &&
                        selectedProgram.promotionTypeCustomFields.length >= 15
                    "
                    :customField="selectedProgram.promotionTypeCustomFields[14]"
                  />
                </v-col>
                <v-col cols="6">
                  <h2 class="mb-2">Promotion Custom Fields</h2>
                  <ol>
                    <li>
                      Configure each custom field at the Program level.
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
      <v-dialog v-model="copyConfirm" max-width="500">
        <v-card :loading="loading">
          <v-card-title>
            Copy Promotion Type
          </v-card-title>
          <v-card-text>
            <p>
              By copying this promotion type, you will be creating a new promotion type. Are you sure?
            </p>
          </v-card-text>

          <v-card-actions
            ><v-spacer></v-spacer>
            <v-btn @click="onCopyCancel">Cancel</v-btn>
            <v-btn class="primary" @click="onCopy">Copy</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../display/ApiError.vue";
import AwardVehiclesField from "../../fields/AwardVehiclesField.vue";
import CountriesField from "../../fields/CountriesField.vue";
import CustomField from "../../fields/CustomField.vue";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";
import KeyField from "../../fields/KeyField.vue";
import PromotionInputTypeField from "../../fields/PromotionInputTypeField.vue";
import PromotionValidationTypesField from "../../fields/PromotionValidationTypesField.vue";
import TermsAndConditionsTable from "../../tables/TermsAndConditionsTable.vue";

export default {
  components: {
    ApiError,
    KeyField,
    PromotionInputTypeField,
    CountriesField,
    AwardVehiclesField,
    DateTimePickerField,
    TermsAndConditionsTable,
    PromotionValidationTypesField,
    CustomField
  },
  name: "PromotionType",
  metaInfo: {
    title: "Promotion Type"
  },
  data() {
    return {
      tab: 0,
      valid: true,
      isNew: false,
      isEditing: false,
      loading: false,
      form: {
        termsAndConditions: []
      },
      errors: {},
      rules: {
        promotionTypeKey: [v => !!v || "Key is required"],
        uploadLimit: [
          v => !!v || "Upload limit is required",
          v => (!!v && v >= 0 && v <= 100) || "It should be less or equal than 99"
        ],
        uploadMinimum: [
          v => !!v || "Upload Minimum count is required",
          v => (!!v && v > 0 && v <= 100) || "It should be less or equal than 99"
        ],
        promotionProductMinimum: [
          v => !!v || "Promotion Product Minimum count is required",
          v => (!!v && v > 0 && v <= 100) || "It should be less or equal than 99"
        ],
        name: [v => !!v || "Name is required"],
        inputType: [v => !!v || "Input Type is required"],
        auditPercent: [
          v => !(v && v.length > 0) || v <= 1 || "Audit Percent should be less or equal than 1",
          v => !(v && v.length > 0) || v >= 0 || "Audit Percent should be greater or equal than 0"
        ]
      },
      copyConfirm: false,

      selectedAvailableAwardVehicles: [],
      selectedPromotionValidationTypes: undefined
    };
  },
  methods: {
    onCopyConfirm() {
      this.copyConfirm = true;
    },
    onCopyCancel() {
      this.copyConfirm = false;
    },
    onCopy() {
      this.loading = true;
      this.errors = {};
      return this.$api
        .post("/api/promotionTypes/" + this.$route.params.id + "/copy", {})
        .then(({ data }) => {
          this.$router.push({
            name: "promotionType",
            params: { id: data.id }
          });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.onCopyCancel();
          this.loading = false;
        });
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let postForm = Object.assign({}, this.form);

      postForm.termsAndConditions.forEach(elem => {
        elem.language = { name: elem.language.name };
        elem.id = undefined;
      });
      if (this.selectedAvailableAwardVehicles) {
        postForm.availableAwardVehicles = this.selectedAvailableAwardVehicles.map(awardVehicle =>
          this.$api.getSelfUrl("awardVehicles", awardVehicle)
        );
      }

      if (this.selectedPromotionValidationTypes) {
        postForm.promotionValidationTypes = this.selectedPromotionValidationTypes.map(pvt => {
          return {
            name: pvt.name
          };
        });
      }

      let serviceCall = null;
      if (this.isNew) {
        serviceCall = this.$api.post("/api/promotionTypes", postForm);
      } else {
        serviceCall = this.$api.patch("/api/promotionTypes/" + this.$route.params.id, postForm);
      }
      serviceCall
        .then(({ data }) => {
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
          }
          this.form = data;
          this.breadcrumb();
        })
        .catch(error => {
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        this.$api
          .get("/api/promotionTypes/" + this.$route.params.id)
          .then(({ data }) => {
            this.form = data;
            this.selectedPromotionValidationTypes = data.promotionValidationTypes;
            this.breadcrumb();

            this.$api.getRelatedObject("availableAwardVehicles", data).then(({ data }) => {
              this.selectedAvailableAwardVehicles = data._embedded.awardVehicles;
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Promotion Types",
            to: { name: "promotionTypes" },
            exact: true
          },
          { text: "New Promotion Type" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Promotion Types",
            to: { name: "promotionTypes" },
            exact: true
          },
          { text: this.form.name }
        ]);
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    title() {
      return "Promotion Type - " + this.form.name;
    },
    showUploadMinimumField() {
      let found = this.selectedPromotionValidationTypes
        ? this.selectedPromotionValidationTypes.find(pvt => pvt.name == "HAS_ENOUGH_UPLOAD")
        : undefined;

      return found != undefined;
    },
    showPromotionProductMinimumField() {
      let found = this.selectedPromotionValidationTypes
        ? this.selectedPromotionValidationTypes.find(pvt => pvt.name == "HAS_ENOUGH_PROMOTION_PRODUCT")
        : undefined;

      return found != undefined;
    }
  },
  mounted() {
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.form.program = this.$api.getSelfUrl("programs", this.selectedProgram);
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
