<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="awardVehicles"
    item-value="id"
    item-text="name"
    clearable
    multiple
    return-object
  >
    <template v-slot:selection="data"> {{ data.item.name }} <br /> </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "AwardVehiclesField",
  props: {
    value: Array,
    available: Array,
    status: { type: String, required: false }
  },
  data: () => ({
    awardVehicles: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      let filterByEffective = true;
      if (this.status && this.status == "Inactive") {
        filterByEffective = false;
      }
      if (this.status && this.status == "All") {
        filterByEffective = undefined;
      }

      if (this.available) {
        this.awardVehicles = this.available;
        if (this.status != "All") {
          this.awardVehicles = this.awardVehicles.filter(awardVehicle => awardVehicle.effective == filterByEffective);
        }
      } else {
        ApiService.post("/api/awardVehicles/search", { isEffective: this.status }).then(({ data }) => {
          this.awardVehicles = data.content;
        });
      }
    }
  },
  watch: {
    available() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
