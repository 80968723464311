<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="promotionValidationTypes"
    item-value="name"
    item-text="description"
    clearable
    multiple
    return-object
  >
    <template v-slot:selection="data">
      {{ data.item.description }}
      <br />
    </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "PromotionValidationTypesField",
  props: {
    value: Array
  },
  data: () => ({
    promotionValidationTypes: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  },
  mounted() {
    ApiService.get("/api/types/promotionValidationTypes").then(({ data }) => {
      this.promotionValidationTypes = data;
    });
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit("change");
      }
    }
  }
};
</script>
