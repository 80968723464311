<template>
  <v-card flat>
    <v-toolbar flat>
      <v-toolbar-title>{{ $i18n.translate("Terms & Conditions") }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="!disabled">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn v-on="{ ...onTooltip }" class="ml-2" fab small>
            <v-icon @click="onAdd()">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table :headers="headers" :items="items" :loading="loading">
        <template v-slot:item.description="{ item }">
          <span v-html="item.description" />
        </template>
        <template v-slot:item.acceptVerbiage="{ item }">
          <span v-html="item.acceptVerbiage" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" v-if="!disabled" @click="onEdit(item)">
            mdi-pencil
          </v-icon>
          <v-icon v-if="!disabled" @click="onDelete(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog persistent v-model="editDialog" v-if="!disabled" width="650px">
        <v-form v-model="valid">
          <v-card>
            <v-card-title>
              <span v-if="isDeleting" class="headline"> Are you sure you want to delete? </span>
              <span v-else-if="isAdding" class="headline"> New {{ $i18n.translate("Terms & Conditions") }}</span>
              <span v-else-if="isEditing" class="headline"> Edit {{ $i18n.translate("Terms & Conditions") }} </span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <LanguageField
                    :label="$i18n.translate('Language')"
                    :disabled="!isAdding"
                    v-model="editedItem.language"
                    :rules="[() => validSelectedLanguage || 'Already Selected']"
                  >
                  </LanguageField>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    :label="$i18n.translate('Terms & Conditions')"
                    v-model="editedItem.description"
                    :readonly="isDeleting"
                  >
                  </v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    :label="$i18n.translate('Accept ') + this.$i18n.translate('Verbiage')"
                    v-model="editedItem.acceptVerbiage"
                    :readonly="isDeleting"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onCancel()">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" :disabled="!valid" @click="onSubmit()">
                <span v-if="isDeleting"> {{ $i18n.translate("Agree") }}</span>
                <span v-else>{{ $i18n.translate("Save") }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import LanguageField from "../fields/LanguageField.vue";
export default {
  components: { LanguageField },
  name: "TermsAndConditionsTable",

  props: {
    value: Array,
    disabled: Boolean
  },
  data: () => ({
    loading: false,
    valid: false,
    loaded: false,
    headers: [
      {
        text: "Language",
        value: "language.description"
      },
      {
        text: "Terms & Conditions",
        value: "description"
      },
      {
        text: "Accept Verbiage",
        value: "acceptVerbiage"
      },
      {
        text: "Actions",
        value: "actions"
      }
    ],
    items: [],
    uploads: [],
    isCreating: false,
    isDeleting: false,
    editDialog: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    rules: {
      language: [v => !!v || "Language is required."]
    },
    errors: {}
  }),

  created() {
    this.items = this.value;
  },

  methods: {
    onEdit(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.upload && this.editedItem.upload.id) {
        this.uploadTermAndCondition = true;
      }
      this.editDialog = true;
    },

    onCancel() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.errors = {};

      this.isCreating = false;
      this.isDeleting = false;
      this.editDialog = false;
    },

    onDelete(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
      this.isDeleting = true;
    },

    onAdd() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editDialog = true;
    },

    onSubmit() {
      this.loading = true;

      if (this.isDeleting) {
        this.items.splice(this.editedIndex, 1);
      } else {
        let newItems = this.items.filter(dtc => dtc.language.name != this.editedItem.language.name);
        newItems.push(this.editedItem);
        this.items = newItems;
      }

      this.onCancel();
      this.$emit("input", this.items);
      this.loading = false;
    }
  },
  computed: {
    isEditing() {
      return this.editedIndex > -1;
    },
    isAdding() {
      return this.editedIndex == -1;
    },
    validSelectedLanguage() {
      if (!this.editedItem.language) {
        return "Language is required";
      } else if (this.isAdding && this.editedItem.language) {
        let alreadySelected = true;
        this.items.forEach((elem, index) => {
          if (
            elem &&
            elem.language &&
            elem.language.name == this.editedItem.language.name &&
            index != this.editedItem
          ) {
            alreadySelected = false;
          }
        });
        return alreadySelected;
      } else {
        return true;
      }
    }
  }
};
</script>
